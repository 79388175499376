@use '../../style/mixins';
@use '../../style/variables' as *;

.VerifyEmailForm {   
    margin-right: 35%;
    .VerifyEmailHeader{
        white-space: pre-line;
        font-size: $mantine-font-size-xl;
        line-height: 60px;
        margin-bottom: 1rem;
    }

    .VerifyEmailSubtitle{
        white-space: pre-line;
        font-size: $mantine-font-size-lg;
        line-height: 36px;
        margin-bottom: 3rem;
    }

    .VerifyEmailLink{
        font-size: $mantine-font-size-md;
        .LinkResendEmail{
            color: $mantine-color-accent-6;
            cursor: pointer;
        }

    }
  
}

