
@use 'src/style/variables' as *;
@use 'src/style/mixins';

.FooterPriceMainBlock {
  border-radius: 5px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  display: flex;
  max-height: 40px;
  border: 1px solid $mantine-color-primary-2;

  .FooterPriceTitle {
    font-family: $mantine-font-family;
    font-size: $mantine-font-size-sm;
    color: $mantine-color-gray-5;
    font-weight: $font-weight-md;
  }

}

