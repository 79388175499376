
@use 'src/style/variables' as *;
@use 'src/style/mixins';

.MainBlock {
  background-color:$mantine-color-gray-3;
  border-radius: 5px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  margin-top: 40px;
  display: flex;
  max-height: 40px;

  .OptionHeaderTitle {
    font-family: $mantine-font-family;
    font-size: $mantine-font-size-md;
    color: $mantine-color-gray-6;
  }

}

