// See theme.ts => theme.breakpoints

// XS
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

// XS
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

// SM
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

// MD
@mixin for-small-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

// LG
@mixin for-medium-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

// XL
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}


@mixin desktop {
  @media only screen and (min-width: 850px) /*and (min-height: $desktop-breakpoint-height)*/ {
    @content;
  }
}

@mixin mobile {
  @media not screen and (min-width: 850px)  /* and (min-height: $desktop-breakpoint-height) */{
    @content;
  }
}