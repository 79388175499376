@use '../../style/variables' as *;
@use '../../style/mixins';



.Layout {
  display: flex;
  flex-direction: column;
  background: $mantine-color-gray-3;

  .Page {
    display: flex;
    flex-grow: 1;
  }
}
