@use '../../style/variables' as *;

.Table {

  .HeaderTable{
    background-color: $mantine-color-gray-3;
    transition: box-shadow 150ms ease;
    border: 1px solid  $mantine-color-gray-3;
  }

  .RowDataTable{
      border:  1px solid $mantine-color-gray-3;;
      border-top: 0px;
  }


}
