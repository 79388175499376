@use '../../style/mixins';
@use '../../style/variables' as *;



.mainDivInput {
    display: flex;
    margin-top: 10px;
    width: auto;

    .TitleInputSubmit {
      font-family: $mantine-font-family;
      font-size: 12px;
      font-weight: $font-weight-md;


    }
    .iconDivInput{
      float: right;
      margin-left: 10px;

    }
}


.DivInput{
    width: 100%;
    display: flex;
    background: #E5ECF2;
    border-radius: 5px;
    margin-top: 6px;
    height: 30px;
    left: 0px;
    top: 25px;

    .IconCross{
      width: auto;
      margin-top: 3px;
      cursor: pointer;
    }
    .ClipIcon{
      width: auto;
      margin-top: 3px;
      margin-left: 14px;
    }

    .InputFile{
        button{
        div,span{
            color: $mantine-color-accent-6;
            text-decoration: underline;
            font-size: $mantine-font-size-xs;
            font-family: $mantine-font-family;

        }
        }
    }

}
