@use '../../style/mixins';
@use '../../style/variables' as *;

.HeaderAuth {
    margin-bottom: 1rem;

  .HeaderTitle{
    font-size: $mantine-font-size-xl;
    line-height: 60px;
    white-space: pre-line;
  }

  .HeaderSubtitle {
    color: $mantine-color-black;
    font-style: normal;
    font-weight: $font-weight-md;
    font-size: $mantine-font-size-lg;
    line-height: 36px;
    margin: 10px 0;
  }

  .SubTitle{
    color: $mantine-color-accent-6;
    font-weight: $font-weight-lg;
  }

}
