@use '../../style/mixins';
@use '../../style/variables' as *;

.SignInForm {

  .GirdLogIn {
    width: 40%;
  }

  .TextInput {
    padding: 5% 0 10%;

  }

  .notification {
    margin-top: 20px;
  }

  .HLink{
    font-family: 'Geometria', serif;
    color: $mantine-color-primary-8
  }

}


