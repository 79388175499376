
@use 'src/style/variables' as *;
@use 'src/style/mixins';

.MainBlock {
  background-color:$mantine-color-gray-3;
  border-radius: 5px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  margin-top: 30px;
  display: flex;
  min-height: 50px;

  .DetailHeaderTitle {
    font-family: $mantine-font-family;
    font-size: $mantine-font-size-sm,;
    color: $mantine-color-gray-5;
    margin: 0 15px;
  }

  .DetailHeaderIcon{
    margin-left: auto;
  }

}

