/**************************************************
 * FONT FAMILY
 **************************************************/
$mantine-font-family: var(--mantine-font-family);
$mantine-font-family-monospace: var(--mantine-font-family-monospace);
$mantine-font-family-headings: var(--mantine-font-family-headings);
$mantine-heading-font-weight: var(--mantine-heading-font-weight);

/**************************************************
 * FONT SIZE
 **************************************************/
$mantine-font-size-xs: var(--mantine-font-size-xs);
$mantine-font-size-sm: var(--mantine-font-size-sm);
$mantine-font-size-md: var(--mantine-font-size-md);
$mantine-font-size-lg: var(--mantine-font-size-lg);
$mantine-font-size-xl: var(--mantine-font-size-xl);


/**************************************************
 * SHADOWS
 **************************************************/
$mantine-shadow-xs: var(--mantine-shadow-xs);
$mantine-shadow-sm: var(--mantine-shadow-sm);
$mantine-shadow-md: var(--mantine-shadow-md);
$mantine-shadow-lg: var(--mantine-shadow-lg);
$mantine-shadow-xl: var(--mantine-shadow-xl);

/**************************************************
 * RADIUS
 **************************************************/
$mantine-radius-xs: var(--mantine-radius-xs);
$mantine-radius-sm: var(--mantine-radius-sm);
$mantine-radius-md: var(--mantine-radius-md);
$mantine-radius-lg: var(--mantine-radius-lg);
$mantine-radius-xl: var(--mantine-radius-xl);

/**************************************************
 * SPACING
 **************************************************/
$mantine-spacing-xs: var(--mantine-spacing-xs);
$mantine-spacing-sm: var(--mantine-spacing-sm);
$mantine-spacing-md: var(--mantine-spacing-md);
$mantine-spacing-lg: var(--mantine-spacing-lg);
$mantine-spacing-xl: var(--mantine-spacing-xl);

/**************************************************
 * CUSTOM COLORS
 **************************************************/
$mantine-color-primary-0: var(--mantine-color-primary-0);
$mantine-color-primary-1: var(--mantine-color-primary-1);
$mantine-color-primary-2: var(--mantine-color-primary-2);
$mantine-color-primary-3: var(--mantine-color-primary-3);
$mantine-color-primary-4: var(--mantine-color-primary-4);
$mantine-color-primary-5: var(--mantine-color-primary-5);
$mantine-color-primary-6: var(--mantine-color-primary-6);
$mantine-color-primary-7: var(--mantine-color-primary-7);
$mantine-color-primary-8: var(--mantine-color-primary-8);
$mantine-color-primary-9: var(--mantine-color-primary-9);

$mantine-color-secondary-0: var(--mantine-color-secondary-0);
$mantine-color-secondary-1: var(--mantine-color-secondary-1);
$mantine-color-secondary-2: var(--mantine-color-secondary-2);
$mantine-color-secondary-3: var(--mantine-color-secondary-3);
$mantine-color-secondary-4: var(--mantine-color-secondary-4);
$mantine-color-secondary-5: var(--mantine-color-secondary-5);
$mantine-color-secondary-6: var(--mantine-color-secondary-6);
$mantine-color-secondary-7: var(--mantine-color-secondary-7);
$mantine-color-secondary-8: var(--mantine-color-secondary-8);
$mantine-color-secondary-9: var(--mantine-color-secondary-9);

$mantine-color-accent-0: var(--mantine-color-accent-0);
$mantine-color-accent-1: var(--mantine-color-accent-1);
$mantine-color-accent-2: var(--mantine-color-accent-2);
$mantine-color-accent-3: var(--mantine-color-accent-3);
$mantine-color-accent-4: var(--mantine-color-accent-4);
$mantine-color-accent-5: var(--mantine-color-accent-5);
$mantine-color-accent-6: var(--mantine-color-accent-6);
$mantine-color-accent-7: var(--mantine-color-accent-7);
$mantine-color-accent-8: var(--mantine-color-accent-8);
$mantine-color-accent-9: var(--mantine-color-accent-9);

$mantine-color-gray-0: var(--mantine-color-gray-0);
$mantine-color-gray-1: var(--mantine-color-gray-1);
$mantine-color-gray-2: var(--mantine-color-gray-2);
$mantine-color-gray-3: var(--mantine-color-gray-3);
$mantine-color-gray-4: var(--mantine-color-gray-4);
$mantine-color-gray-5: var(--mantine-color-gray-5);
$mantine-color-gray-6: var(--mantine-color-gray-6);
$mantine-color-gray-7: var(--mantine-color-gray-7);
$mantine-color-gray-8: var(--mantine-color-gray-8);
$mantine-color-gray-9: var(--mantine-color-gray-9);

$mantine-color-success-0: var(--mantine-color-success-0);
$mantine-color-success-1: var(--mantine-color-success-1);
$mantine-color-success-2: var(--mantine-color-success-2);
$mantine-color-success-3: var(--mantine-color-success-3);
$mantine-color-success-4: var(--mantine-color-success-4);
$mantine-color-success-5: var(--mantine-color-success-5);
$mantine-color-success-6: var(--mantine-color-success-6);
$mantine-color-success-7: var(--mantine-color-success-7);
$mantine-color-success-8: var(--mantine-color-success-8);
$mantine-color-success-9: var(--mantine-color-success-9);

$mantine-color-warning-0: var(--mantine-color-warning-0);
$mantine-color-warning-1: var(--mantine-color-warning-1);
$mantine-color-warning-2: var(--mantine-color-warning-2);
$mantine-color-warning-3: var(--mantine-color-warning-3);
$mantine-color-warning-4: var(--mantine-color-warning-4);
$mantine-color-warning-5: var(--mantine-color-warning-5);
$mantine-color-warning-6: var(--mantine-color-warning-6);
$mantine-color-warning-7: var(--mantine-color-warning-7);
$mantine-color-warning-8: var(--mantine-color-warning-8);
$mantine-color-warning-9: var(--mantine-color-warning-9);

$mantine-color-alert-0: var(--mantine-color-alert-0);
$mantine-color-alert-1: var(--mantine-color-alert-1);
$mantine-color-alert-2: var(--mantine-color-alert-2);
$mantine-color-alert-3: var(--mantine-color-alert-3);
$mantine-color-alert-4: var(--mantine-color-alert-4);
$mantine-color-alert-5: var(--mantine-color-alert-5);
$mantine-color-alert-6: var(--mantine-color-alert-6);
$mantine-color-alert-7: var(--mantine-color-alert-7);
$mantine-color-alert-8: var(--mantine-color-alert-8);
$mantine-color-alert-9: var(--mantine-color-alert-9);

/**************************************************
 * COLORS
 **************************************************/
$mantine-color-white: var(--mantine-color-white);
$mantine-color-black: var(--mantine-color-black);

//$mantine-color-dark-0: var(--mantine-color-dark-0);
//$mantine-color-dark-1: var(--mantine-color-dark-1);
//$mantine-color-dark-2: var(--mantine-color-dark-2);
//$mantine-color-dark-3: var(--mantine-color-dark-3);
//$mantine-color-dark-4: var(--mantine-color-dark-4);
//$mantine-color-dark-5: var(--mantine-color-dark-5);
//$mantine-color-dark-6: var(--mantine-color-dark-6);
//$mantine-color-dark-7: var(--mantine-color-dark-7);
//$mantine-color-dark-8: var(--mantine-color-dark-8);
//$mantine-color-dark-9: var(--mantine-color-dark-9);
//
//$mantine-color-gray-0: var(--mantine-color-gray-0);
//$mantine-color-gray-1: var(--mantine-color-gray-1);
//$mantine-color-gray-2: var(--mantine-color-gray-2);
//$mantine-color-gray-3: var(--mantine-color-gray-3);
//$mantine-color-gray-4: var(--mantine-color-gray-4);
//$mantine-color-gray-5: var(--mantine-color-gray-5);
//$mantine-color-gray-6: var(--mantine-color-gray-6);
//$mantine-color-gray-7: var(--mantine-color-gray-7);
//$mantine-color-gray-8: var(--mantine-color-gray-8);
//$mantine-color-gray-9: var(--mantine-color-gray-9);
//
//$mantine-color-red-0: var(--mantine-color-red-0);
//$mantine-color-red-1: var(--mantine-color-red-1);
//$mantine-color-red-2: var(--mantine-color-red-2);
//$mantine-color-red-3: var(--mantine-color-red-3);
//$mantine-color-red-4: var(--mantine-color-red-4);
//$mantine-color-red-5: var(--mantine-color-red-5);
//$mantine-color-red-6: var(--mantine-color-red-6);
//$mantine-color-red-7: var(--mantine-color-red-7);
//$mantine-color-red-8: var(--mantine-color-red-8);
//$mantine-color-red-9: var(--mantine-color-red-9);
//
//$mantine-color-pink-0: var(--mantine-color-pink-0);
//$mantine-color-pink-1: var(--mantine-color-pink-1);
//$mantine-color-pink-2: var(--mantine-color-pink-2);
//$mantine-color-pink-3: var(--mantine-color-pink-3);
//$mantine-color-pink-4: var(--mantine-color-pink-4);
//$mantine-color-pink-5: var(--mantine-color-pink-5);
//$mantine-color-pink-6: var(--mantine-color-pink-6);
//$mantine-color-pink-7: var(--mantine-color-pink-7);
//$mantine-color-pink-8: var(--mantine-color-pink-8);
//$mantine-color-pink-9: var(--mantine-color-pink-9);
//
//$mantine-color-grape-0: var(--mantine-color-grape-0);
//$mantine-color-grape-1: var(--mantine-color-grape-1);
//$mantine-color-grape-2: var(--mantine-color-grape-2);
//$mantine-color-grape-3: var(--mantine-color-grape-3);
//$mantine-color-grape-4: var(--mantine-color-grape-4);
//$mantine-color-grape-5: var(--mantine-color-grape-5);
//$mantine-color-grape-6: var(--mantine-color-grape-6);
//$mantine-color-grape-7: var(--mantine-color-grape-7);
//$mantine-color-grape-8: var(--mantine-color-grape-8);
//$mantine-color-grape-9: var(--mantine-color-grape-9);
//
//$mantine-color-violet-0: var(--mantine-color-violet-0);
//$mantine-color-violet-1: var(--mantine-color-violet-1);
//$mantine-color-violet-2: var(--mantine-color-violet-2);
//$mantine-color-violet-3: var(--mantine-color-violet-3);
//$mantine-color-violet-4: var(--mantine-color-violet-4);
//$mantine-color-violet-5: var(--mantine-color-violet-5);
//$mantine-color-violet-6: var(--mantine-color-violet-6);
//$mantine-color-violet-7: var(--mantine-color-violet-7);
//$mantine-color-violet-8: var(--mantine-color-violet-8);
//$mantine-color-violet-9: var(--mantine-color-violet-9);
//
//$mantine-color-indigo-0: var(--mantine-color-indigo-0);
//$mantine-color-indigo-1: var(--mantine-color-indigo-1);
//$mantine-color-indigo-2: var(--mantine-color-indigo-2);
//$mantine-color-indigo-3: var(--mantine-color-indigo-3);
//$mantine-color-indigo-4: var(--mantine-color-indigo-4);
//$mantine-color-indigo-5: var(--mantine-color-indigo-5);
//$mantine-color-indigo-6: var(--mantine-color-indigo-6);
//$mantine-color-indigo-7: var(--mantine-color-indigo-7);
//$mantine-color-indigo-8: var(--mantine-color-indigo-8);
//$mantine-color-indigo-9: var(--mantine-color-indigo-9);
//
//$mantine-color-blue-0: var(--mantine-color-blue-0);
//$mantine-color-blue-1: var(--mantine-color-blue-1);
//$mantine-color-blue-2: var(--mantine-color-blue-2);
//$mantine-color-blue-3: var(--mantine-color-blue-3);
//$mantine-color-blue-4: var(--mantine-color-blue-4);
//$mantine-color-blue-5: var(--mantine-color-blue-5);
//$mantine-color-blue-6: var(--mantine-color-blue-6);
//$mantine-color-blue-7: var(--mantine-color-blue-7);
//$mantine-color-blue-8: var(--mantine-color-blue-8);
//$mantine-color-blue-9: var(--mantine-color-blue-9);
//
//$mantine-color-cyan-0: var(--mantine-color-cyan-0);
//$mantine-color-cyan-1: var(--mantine-color-cyan-1);
//$mantine-color-cyan-2: var(--mantine-color-cyan-2);
//$mantine-color-cyan-3: var(--mantine-color-cyan-3);
//$mantine-color-cyan-4: var(--mantine-color-cyan-4);
//$mantine-color-cyan-5: var(--mantine-color-cyan-5);
//$mantine-color-cyan-6: var(--mantine-color-cyan-6);
//$mantine-color-cyan-7: var(--mantine-color-cyan-7);
//$mantine-color-cyan-8: var(--mantine-color-cyan-8);
//$mantine-color-cyan-9: var(--mantine-color-cyan-9);
//
//$mantine-color-teal-0: var(--mantine-color-teal-0);
//$mantine-color-teal-1: var(--mantine-color-teal-1);
//$mantine-color-teal-2: var(--mantine-color-teal-2);
//$mantine-color-teal-3: var(--mantine-color-teal-3);
//$mantine-color-teal-4: var(--mantine-color-teal-4);
//$mantine-color-teal-5: var(--mantine-color-teal-5);
//$mantine-color-teal-6: var(--mantine-color-teal-6);
//$mantine-color-teal-7: var(--mantine-color-teal-7);
//$mantine-color-teal-8: var(--mantine-color-teal-8);
//$mantine-color-teal-9: var(--mantine-color-teal-9);
//
//$mantine-color-green-0: var(--mantine-color-green-0);
//$mantine-color-green-1: var(--mantine-color-green-1);
//$mantine-color-green-2: var(--mantine-color-green-2);
//$mantine-color-green-3: var(--mantine-color-green-3);
//$mantine-color-green-4: var(--mantine-color-green-4);
//$mantine-color-green-5: var(--mantine-color-green-5);
//$mantine-color-green-6: var(--mantine-color-green-6);
//$mantine-color-green-7: var(--mantine-color-green-7);
//$mantine-color-green-8: var(--mantine-color-green-8);
//$mantine-color-green-9: var(--mantine-color-green-9);
//
//$mantine-color-lime-0: var(--mantine-color-lime-0);
//$mantine-color-lime-1: var(--mantine-color-lime-1);
//$mantine-color-lime-2: var(--mantine-color-lime-2);
//$mantine-color-lime-3: var(--mantine-color-lime-3);
//$mantine-color-lime-4: var(--mantine-color-lime-4);
//$mantine-color-lime-5: var(--mantine-color-lime-5);
//$mantine-color-lime-6: var(--mantine-color-lime-6);
//$mantine-color-lime-7: var(--mantine-color-lime-7);
//$mantine-color-lime-8: var(--mantine-color-lime-8);
//$mantine-color-lime-9: var(--mantine-color-lime-9);
//
//$mantine-color-yellow-0: var(--mantine-color-yellow-0);
//$mantine-color-yellow-1: var(--mantine-color-yellow-1);
//$mantine-color-yellow-2: var(--mantine-color-yellow-2);
//$mantine-color-yellow-3: var(--mantine-color-yellow-3);
//$mantine-color-yellow-4: var(--mantine-color-yellow-4);
//$mantine-color-yellow-5: var(--mantine-color-yellow-5);
//$mantine-color-yellow-6: var(--mantine-color-yellow-6);
//$mantine-color-yellow-7: var(--mantine-color-yellow-7);
//$mantine-color-yellow-8: var(--mantine-color-yellow-8);
//$mantine-color-yellow-9: var(--mantine-color-yellow-9);
//
//$mantine-color-orange-0: var(--mantine-color-orange-0);
//$mantine-color-orange-1: var(--mantine-color-orange-1);
//$mantine-color-orange-2: var(--mantine-color-orange-2);
//$mantine-color-orange-3: var(--mantine-color-orange-3);
//$mantine-color-orange-4: var(--mantine-color-orange-4);
//$mantine-color-orange-5: var(--mantine-color-orange-5);
//$mantine-color-orange-6: var(--mantine-color-orange-6);
//$mantine-color-orange-7: var(--mantine-color-orange-7);
//$mantine-color-orange-8: var(--mantine-color-orange-8);
//$mantine-color-orange-9: var(--mantine-color-orange-9);

/**************************************************
 * HEADERS
 **************************************************/
$mantine-h1-font-size: var(--mantine-h1-font-size);
$mantine-h1-line-height: var(--mantine-h1-line-height);
$mantine-h2-font-size: var(--mantine-h2-font-size);
$mantine-h2-line-height: var(--mantine-h2-line-height);
$mantine-h3-font-size: var(--mantine-h3-font-size);
$mantine-h3-line-height: var(--mantine-h3-line-height);
$mantine-h4-font-size: var(--mantine-h4-font-size);
$mantine-h4-line-height: var(--mantine-h4-line-height);
$mantine-h5-font-size: var(--mantine-h5-font-size);
$mantine-h5-line-height: var(--mantine-h5-line-height);
$mantine-h6-font-size: var(--mantine-h6-font-size);
$mantine-h6-line-height: var(--mantine-h6-line-height);

/**************************************************
 * MISC
 **************************************************/
$mantine-transition-timing-function: var(--mantine-transition-timing-function);
$mantine-line-height: var(--mantine-line-height);


/**************************************************
 * FONT WEIGHT
 **************************************************/
$font-weight-md: 500;
$font-weight-lg: 700;
$font-weight-xl: 800;
