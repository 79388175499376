@use 'fonts';
@use 'mixins';
@use 'variables' as *;

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background: white;
}

*,
*.after,
*:before {
  box-sizing: border-box;
}

body {
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: $font-weight-md;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    'Open Sans', monospace;
}

input, div{
   font-weight: $font-weight-md;
}

button{
    font-weight: $font-weight-md;

}
