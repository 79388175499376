@use '../../style/mixins';
@use '../../style/variables' as *;

.Error404Page {
  padding-top: 5rem;
  padding-bottom: 5rem;

  .label {
    text-align: center;
    font-weight: 900;
    font-size: 7.5rem;
    line-height: 1;
    margin-bottom: calc($mantine-spacing-xl * 1.5);
    color: $mantine-color-gray-2;
  }

  .title {
    font-family: Greycliff CF, $mantine-font-family;
    text-align: center;
    font-weight: 900;
    font-size: 2rem;
  }

  .description {
    max-width: 31.25rem;
    margin: $mantine-spacing-xl auto calc($mantine-spacing-xl * 1.5);
  }
}

@include mixins.for-tablet-portrait-up {
  .Error404Page {
    .label {
      font-size: 13.75rem;
    }

    .title {
      font-size: 2.375rem;
    }
  }
}
