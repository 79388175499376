@use '../../style/mixins';
@use '../../style/variables' as *;

.ResetPasswordForm {

  .GirdEmail {
    width: 60%;
    margin-top: 20px;
  }

  .TextInput {
    padding-bottom: 10%;

  }

  .notification {
    margin-top: 20px;
  }

  .HLink{
    color: $mantine-color-accent-6;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;

  }


}


