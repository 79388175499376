@use '../../style/mixins';
@use '../../style/variables' as *;

.Card {
    background-color: $mantine-color-white;
    min-height: 640px;
    max-width: 1360px;
    border-radius: 5px;
    margin: 15px auto;
    padding: 25px;

    .HeaderCard{
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        flex-direction: row;

        .Title{
            font-family: $mantine-font-family;
            font-size: $mantine-font-size-md;
            font-weight: $font-weight-md;
            color: $mantine-color-gray-5;
            letter-spacing: 0em;
        }

    }

}
