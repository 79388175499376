@use '../../style/variables' as *;
@use '../../style/mixins';

.NoInstrumentsBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 25px;

      .TitleInfoBox{
        font-family: $mantine-font-family;
        font-size: $mantine-font-size-md;
        font-weight: $font-weight-lg;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: center;
        color: $mantine-color-gray-5;
        .SubtitleInfoBox{
          font-family: $mantine-font-family;
          font-size: $mantine-font-size-md;
          font-weight: $font-weight-md;
          line-height: 23px;
          letter-spacing: 0em;
          text-align: center;
          color: $mantine-color-gray-4;
          width: 302px;
          margin: 20px auto 0;
        }
    
      }


}
  
