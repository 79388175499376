
@use 'src/style/variables' as *;
@use 'src/style/mixins';

.GridSpace{
  margin-left: 10px;
  margin-right: 10px;
}

.TextTitleJobOrderView{
  color: $mantine-color-gray-5;
  font-variant: all-petite-caps;
  font-size: $mantine-font-size-md;
}

.TextInterventionType{
  width: 270px;
}
.ShowInTicketDetailNote{
    color: $mantine-color-gray-9;
    width:  auto;
    height: auto;
}
