@use '../../style/mixins';
@use '../../style/variables' as *;

.MainBox{
  border: 1px solid $mantine-color-primary-8;
  width: 315px;
  height: 67px;
  border-radius: 5px;

  .ContainerDiv{
    display: flex;
    flex-direction: row;

   }
  .StatusTitle{
    color: $mantine-color-gray-8;
    font-variant: all-small-caps;
    font-family: $mantine-font-family;
    font-size: $mantine-font-size-sm;
    margin-top: 19px;
    margin-left: 20px;
  }
  .NumberOfStatus{
    color:$mantine-color-primary-8;
    margin-top: -10%;
    font-size: 30px;
    float: right;
    margin-right: 12px;


  }
}
