@use '../../style/mixins';
@use '../../style/variables' as *;

.PasswordChangeForm {
    margin-right: 40%;
    .TextInput {
        padding-bottom: 10%;
    
      }
  .notification {
    margin-top: 20px;
  }

}


