@use 'src/style/variables' as *;
@use 'src/style/mixins';

.ButtonConfirm{
  float: right;
}
.ButtonAbort{
  background-color: $mantine-color-gray-5;
}

.SubTitleModal{
  font-family: $mantine-font-family;
  font-size: $mantine-font-size-md;
  color: $mantine-color-gray-6;
}

.SelectOption{
  margin-top: 14px;
  margin-bottom: 55px;
}
