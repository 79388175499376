@use '../../style/mixins';
@use '../../style/variables' as *;

.Address {
  margin: 10px;

    .EnableShippingAddress{
        padding-top: 32px;
    }
}
