@use '../../style/mixins';
@use '../../style/variables' as *;

.LayoutAuth {
    min-height: 100vh;
    display: flex;
    font-family: $mantine-font-family;

    .ContainerLeft{
        @include mixins.desktop{width: 50%;}
        @include mixins.mobile{width: 100%;}

        padding: 3rem ;
        background-color: $mantine-color-white;

        .HeaderLogo{
            width: 20%;
            margin-bottom: 5%;

            .Logo {
                width: 100%;
                height: 100%;
            }
        }

        .ContainerFrom{
            min-height: 70%;
            margin-left: 10px;
        }

    }

    .ContainerRight{
        position: relative;
        width: 50%;
        background: url("../../../public/cover.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @include mixins.mobile{ display: none;}

        .TitleCover{
            width: 100%;
            color: $mantine-color-white;
            position: absolute;
            text-align: left;
            bottom: 0;
            padding: 0 5rem 5rem;
            font-style: normal;
            font-weight: 800;
            font-size: 44px;
            line-height: 55px;
            white-space: pre-line;
        }
    }

}

