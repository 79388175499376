@use 'src/style/variables' as *;
@use 'src/style/mixins';

.MainDiv{
  .BodyCard{
    margin: 10px;
  }

  .ButtonCancel{
    float: left;
    background-color: $mantine-color-gray-5;
  }
  .ButtonCancel:hover{
    background-color: $mantine-color-gray-6;
  }
  .ButtonConfirm{
    float: right;
  }

 
  .ActionTitle{
    font-family: $mantine-font-family;
    font-size: $mantine-font-size-sm;
    color: $mantine-color-gray-6;
    float: left;
  }
}
