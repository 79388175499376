@use '../../style/variables' as *;
@use '../../style/mixins';


/*INSTRUMENTS MODEL*/

.checkBox{
    margin-top: 20px;

    label{
      font-size: $mantine-font-size-sm;
      font-family: $mantine-font-family;
      font-weight: $font-weight-md;

    }
}

.ContainerButtonModal{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 0;
    .ButtonCancel{
        background-color: $mantine-color-gray-5;
    }

}


/*INSTRUMENTS*/
.FilterOptionRight{
    width: 50%;
}

.FilterOptionLeft{
    display: flex;
    width: 50%;

    .TextInput{
        min-width: 50%;
    }
}

.ButtonIconMenu:hover{
    padding: initial;
    background-color: transparent;
    cursor: pointer;
}

.ButtonIconMenu{
  padding: initial;
  background-color: transparent;
  cursor: pointer;
}

.ButtonDot{
  cursor: pointer;
}

.FilterTypeProduct{
    min-width: 40%;
}
.Ellipsis{
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ButtonCancel{
  float: left;
  background-color: $mantine-color-gray-5;
}
.ButtonCancel:hover{
  background-color: $mantine-color-gray-6;
}

.ButtonConfirm{
  float: right;
}












