@use '../../style/mixins';
@use '../../style/variables' as *;

.SignUpForm {
    margin-right: 30%;


    .Alert{
        margin-bottom: 1rem;
    }

    .SignUpButton{
        margin-top: 1rem;
    }

}

