@font-face {
    font-family: 'Geometria';
    font-style: normal;
    font-weight: 700;
    src: local('Geometria'), url('./fonts/Geometria.ttf') format('truetype');
}
@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 700;
  src: local('Geometria'), url('./fonts/Geometria-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Geometria';
  font-style: italic;
  font-weight: 700;
  src: local('Geometria'), url('./fonts/Geometria-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 800;
  src: local('Geometria'), url('./fonts/Geometria-ExtraBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Geometria';
    font-style: normal;
    font-weight: 800;
    src: local('Geometria'), url('./fonts/Geometria-ExtraBoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Geometria';
    font-style: normal;
    font-weight: 800;
    src: local('Geometria'), url('./fonts/Geometria-ExtraLight.ttf') format('truetype');
}
@font-face {
    font-family: 'Geometria';
    font-style: normal;
    font-weight: 800;
    src: local('Geometria'), url('./fonts/Geometria-ExtraLightItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Geometria';
    font-style: normal;
    font-weight: 800;
    src: local('Geometria'), url('./fonts/Geometria-Heavy.ttf') format('truetype');
}
@font-face {
    font-family: 'Geometria';
    font-style: normal;
    font-weight: 800;
    src: local('Geometria'), url('./fonts/Geometria-HeavyItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Geometria';
    font-style: normal;
    font-weight: 800;
    src: local('Geometria'), url('./fonts/Geometria-Italic.ttf') format('truetype');
}
@font-face {
    font-family: 'Geometria';
    font-style: normal;
    font-weight: 800;
    src: local('Geometria'), url('./fonts/Geometria-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Geometria';
    font-style: normal;
    font-weight: 800;
    src: local('Geometria'), url('./fonts/Geometria-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 500;
  src: local('Geometria'), url('./fonts/Geometria-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Geometria';
  font-style: italic;
  font-weight: 500;
  src: local('Geometria'), url('./fonts/Geometria-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 400;
  src: local('Geometria'), url('./fonts/Geometria-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Geometria';
  font-style: italic;
  font-weight: 400;
  src: local('Geometria'), url('./fonts/Geometria-ThinItalic.ttf') format('truetype');
}
//TODO: monserrat
