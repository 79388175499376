@use '../../style/mixins';
@use '../../style/variables' as *;



.MainDivDropDown{
  display: flex;
  flex-direction: row-reverse;
  .ContainerLink{
    background-color: $mantine-color-white;
    position: fixed;
    margin-top: 55px;
    margin-right: -39px;
  }
  .RectangleDiv{
    position: inherit;
    margin-top: -14px;
    margin-left: 43px;
  }
  .UlStyle{
    list-style: none;
    display: flex;
    flex-direction: column;
    margin-right: 35px;
    margin-left: -23px;
  }
  .ListStyle{
    padding: 5px;
    cursor: pointer;
  }
  .TextDecoration{
    text-decoration: none;
    font-family: $mantine-font-family;
    color: $mantine-color-gray-9;
    font-size: $mantine-font-size-sm;
    font-weight: $font-weight-md;
    list-style-type: none;
  }
}
