@use '../../style/variables' as *;
@use '../../style/mixins';

/*CREATE TICKET*/
.ButtonBackModalTicket{
    width: 100%;
}

.ButtonAddJobOrder{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $mantine-color-white;
    border: 1.8px dashed $mantine-color-gray-5;
    color: $mantine-color-black;
    padding: 5px 10px;
    width: 100%;
    border-radius: 5px;
    line-height: 20px;
    font-size: 14px;
    margin-top: 15px;
}

.ButtonAddJobOrder:hover{
  background: $mantine-color-gray-3;
}

.CheckBoxMargin{
    margin-top: 35px;
}

.checkBox{
    margin-top: 10px;
    display: flex;

    label{
      font-size: $mantine-font-size-sm;
      font-family: $mantine-font-family;
      font-weight: $font-weight-md;
    }
}

.CostOfInterventionInfo{
    margin: 40px 5px 0;
    font-family: $mantine-font-family;
    font-size:$mantine-font-size-md;
    color: $mantine-color-gray-5;
    font-weight: $font-weight-lg;
}

.ContainerButtonModal{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 0;
    .ButtonCancel{
      background-color: $mantine-color-gray-5;
    }
}
