@use '../../style/mixins';
@use '../../style/variables' as *;

.InfoBox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;
    gap: 10px;

    border: 1px solid $mantine-color-gray-3;
    border-radius: $mantine-radius-xs;
    background-color: $mantine-color-gray-3;   
    color:  $mantine-color-gray-5;
    
    .InfoIcon{
        width: 25px;
        height: 25px;
    }

    .Context{
        font-style: normal;
        font-weight: $font-weight-md;
        font-size: $mantine-font-size-xs;
        line-height: 15px;
    }
    
}
