
@use 'src/style/variables' as *;
@use 'src/style/mixins';

.MainBlock {
  background-color:$mantine-color-primary-2;
  padding: 6.5px;
  border-radius: 5px;


  .OptionHeaderInfoBoxTitle {
    font-family: $mantine-font-family;
    font-size: $mantine-font-size-sm;
    color: $mantine-color-primary-8;
  }

}
