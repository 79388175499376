@use '../../style/variables' as *;
@use '../../style/mixins';


/*FILTER*/
.FilterOptionRight{
  width: 50%;
}

.FilterOptionLeft{
  display: flex;
  width: 50%;

  .TextInput{
    min-width: 50%;
  }
}

.FilterTypeProduct{
  min-width: 40%;
}


/*TABLE TICKES*/
.NestedElements{
    background-color: $mantine-color-gray-2;
    border: 2.5px solid white;
}

.ButtonTicketId{
    margin: 0;
    text-decoration: underline;
}

.ButtonTicketId:hover{
  cursor: pointer;
}

.ButtonIconMenu{
  padding: initial;
  background-color: transparent;
  cursor: pointer;
}

.ButtonDot{
  cursor: pointer;
}

.ButtonIconMenu:hover{
  padding: initial;
  background-color: transparent;
  cursor: pointer;
}

.ActionsContainer{
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ButtonIconMenu{
  padding: initial;
  background-color: transparent;
  cursor: pointer;
}

.RotateElement{
  transform: rotate(3.142rad);
}

.Ellipsis{
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

