@use '../../style/mixins';
@use '../../style/variables' as *;

.topHeader {

  .ContainerLogo{
    width: 100px;
  }

  .navBar {
    display: flex;
    width: 100%;
    height: 60px;
    background: $mantine-color-white;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
    align-items: center;
    padding-left: 2rem;
  }

  .linkNavBar {
    height: 100%;
    margin-left: 50px;
    @include mixins.mobile {
      display: none;
    }
  }

  .lineBottom {
    height: 100%;
    display: flex;

  }

  .Href {
    font-family: $mantine-font-family;
    font-size: $mantine-font-size-md;
    font-weight: $font-weight-lg;
    line-height: 62px;
    text-align: center;
    text-decoration: none;
    color: $mantine-color-primary-9;
    margin-right: 20px;
    border-bottom: 5px solid $mantine-color-primary-9;
    height: 100%;
  }

  .unSelected {
    font-family: $mantine-font-family;
    font-size: $mantine-font-size-md;
    line-height: 62px;
    text-align: center;
    text-decoration: none;
    color: $mantine-color-gray-5;
    margin-right: 20px;
    height: 100%;
  }

  .dropdown {
    position: absolute;
    overflow: hidden;
    left: 0;
    top: 60px;
    right: 0;
    margin-left: 20px;

  }


  .burger {

    @include mixins.desktop {
      display: none;
    }

  }

  .image {

    @include mixins.mobile {
      display: none;
    }

  }
  .UserIcon{
    margin-top: -47px;
    margin-right: 6%;
  }

  @include mixins.for-tablet-portrait-up {
  }
}
